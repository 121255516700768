import styled from '@emotion/styled';
import { Typography } from '@material-ui/core';
import { Proposals } from '../components/Proposal';
import React from 'react';
import tw from 'twin.macro';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { HomePage } from '@plan-virtual/app/HomePage';
import { QrPage } from '@plan-virtual/app/QrPage';
import { PrivacyPolicyPage } from '@plan-virtual/app/PrivacyPolicyPage';
import { ProposalsManagerPage } from './ProposalsManagerPage';

export const App = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/">
          <HomeContainer>
            <Container>
              <FirstSection tw="relative">
                {/* <img
            alt="Rectoria Torre"
            src="/assets/Torre.png"
            tw="absolute top-0 left-0 h-full object-cover"
          /> */}
                <img src="/assets/rectornuevo.png" alt="Rector" />
                <Rectorado>
                  <Typography variant="h5" color="white">
                    Dr. med. Santos Guzmán López
                  </Typography>
                </Rectorado>
                <Typography variant="h6" fontWeight="400" color="#ffffff">
                  La excelencia como principio, <br /> la educación como
                  instrumento
                </Typography>
                <Typography
                  tw="mt-auto"
                  fontWeight="700"
                  color="primary"
                  variant="h2"
                >
                  UANL
                </Typography>
              </FirstSection>
              <ProposalsContainer>
                <TopBarContainer tw="mb-8">
                  <TopBar>
                    <SkewedBar />
                  </TopBar>
                  <img alt="Antorcha" src="/assets/logo.png" />
                </TopBarContainer>
                <Proposals />
              </ProposalsContainer>
            </Container>
          </HomeContainer>
        </Route>
        <Route exact path="/administrador">
          <ProposalsManagerPage />
        </Route>
      </Switch>
    </BrowserRouter>
  );
};

const HomeContainer = styled.div`
  width: 100vw;
  height: 100vh;
`;

const SkewedBar = styled.div``;

const TopBar = styled.div`
  overflow: hidden;
  width: 100%;
  height: 4em;
  position: relative;
  background: #d8d8d8;

  ${SkewedBar} {
    width: 35%;
    height: 100%;
    transform: skew(-30deg);
    position: absolute;
    top: 0;
    left: -2em;
    background: #d8d8d8;
  }
`;

const TopBarContainer = styled.div`
  width: 100%;
  position: relative;

  img {
    height: 7em;
    position: absolute;
    bottom: -3.5em;
    right: 1em;
  }
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  max-width: 100%;
  box-sizing: border-box;
  padding 0 0 0 1em;
  display: grid;
  grid-template-columns: 0.3fr 0.7fr;
  grid-template-rows: 1fr;
`;

const FirstSection = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  gap: 1em;
  padding: 2em;
  border-right: 1px solid #33333366;
  background: url('/assets/Torre.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

const PresentationContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
`;

const RectorContainer = styled.div`
  width: 100%;
  margin-top: 4em;

  p {
    background: #464545;
    color: white;
    padding: 0.3em 1em;
    margin: 0.5em 0;
    text-align: center;
  }
`;

const Rectorado = styled.div`
  background: #b78c1c;
  padding: 0.2em 1em;
`;

const ProposalsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  .title {
    background: ${({ theme }) => theme.palette.secondary.main};
    padding: 0.5em;
    text-align: center;
    width: 50%;
  }
`;

export default App;
