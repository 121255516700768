import firebase from 'firebase/app';
import {
  firebaseConfig,
  proposalsCollectionName,
} from '@oop/plan-virtual-data';
import 'firebase/firestore';
import { ReadProposalDto } from '@oop/plan-virtual-data';

const app = firebase.initializeApp(firebaseConfig);
const db = app.firestore();

export const proposalsCollection = db.collection(
  proposalsCollectionName
) as firebase.firestore.CollectionReference<ReadProposalDto>;

export { db };
