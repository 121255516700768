import tw from 'twin.macro';
import styled from '@emotion/styled';
import theme from './theme';

/**
 *  Css variables used by colors in tailwind config
 */
const GlobalStyles = styled.div`
  --bg-primary: ${theme.palette.primary.main};
  --bg-secondary: ${theme.palette.secondary.main};

  --text-primary: ${theme.palette.primary.main};
  --text-secondary: ${theme.palette.secondary.main};
`;

export default GlobalStyles;
