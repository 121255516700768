import {
  CircularProgress,
  Typography,
  Fade,
  LinearProgress,
} from '@material-ui/core';
import { ReadProposalDto } from '@oop/plan-virtual-data';
import React, { useEffect, useState } from 'react';
import { useCollection } from 'react-firebase-hooks/firestore';
import { proposalsCollection } from '../services/firebase';
import { Skeleton } from '@material-ui/lab';
import { addSeconds } from 'date-fns';

interface ProposalProps {
  proposal: Omit<ReadProposalDto, 'createdAt'>;
}

const CAROUSEL_TIME = 20000;

export const Proposals = () => {
  const [snapshot, loading, error] = useCollection(
    proposalsCollection.orderBy('createdAt', 'asc')
  );
  const [nextSSIsNewProposal, setNextSSIsNewProposal] = useState(false);
  const [nextProposal, setNextProposal] = useState(0);
  let proposals = [] as ReadProposalDto[];
  if (snapshot !== undefined) {
    proposals = snapshot.docs
      .map((doc) => ({ id: doc.id, ...doc.data() } as ReadProposalDto))
      .filter((proposal) => proposal.active);
  }
  useEffect(() => {
    console.log(error);
    if (snapshot !== undefined) {
      setNextSSIsNewProposal(true);
    }
    if (!snapshot) return;
  }, [snapshot, error]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (loading) return;

      console.log('nextProposal', nextProposal);
      setNextProposal(
        nextProposal === proposals.length - 1 ? 0 : nextProposal + 1
      );
    }, CAROUSEL_TIME);
    return () => clearInterval(intervalId);
  }, [snapshot, nextProposal]);

  return (
    <div tw="w-full h-full p-16 flex flex-col justify-between">
      <div tw="relative w-full">
        {proposals.length !== 0 ? (
          proposals.map((currentProposal, i) => (
            <Fade
              mountOnEnter={false}
              in={i === nextProposal}
              timeout={300}
              unmountOnExit
            >
              <div tw="absolute w-full top-0 left-0">
                <Proposal
                  key={currentProposal.proposal}
                  proposal={{
                    email: currentProposal.email,
                    id: currentProposal.id,
                    name: currentProposal.name,
                    plan: currentProposal.plan,
                    proposal: currentProposal.proposal,
                    active: currentProposal.active,
                    timesShown: 0,
                  }}
                />
              </div>
            </Fade>
          ))
        ) : (
          <ProposalSkeleton />
        )}
      </div>
      <div tw="w-full flex flex-col gap-8">
        <img
          tw="w-24 opacity-40 mr-auto"
          alt="logo"
          src="/assets/Logo-Excelencia-Negro.png"
        />
        <TimeRemainingTilNextProposal current={loading ? null : nextProposal} />
      </div>
    </div>
  );
};

const TimeRemainingTilNextProposal: React.VFC<{
  secondsAfter?: number;
  current: number | null;
}> = ({ secondsAfter = CAROUSEL_TIME / 1000, current }) => {
  const [progress, setProgress] = useState(0);
  useEffect(() => {
    if (current === null) return;
    const until = addSeconds(new Date(), secondsAfter);
    const intervalId = setInterval(() => {
      const diffInSeconds =
        until.getTime() / 1000 - new Date().getTime() / 1000;

      setProgress((diffInSeconds / secondsAfter) * 100);
    }, 100);
    return () => clearInterval(intervalId);
  }, [current]);
  return (
    <LinearProgress variant="determinate" value={Math.min(progress, 100)} />
  );
};

const ProposalSkeleton = () => {
  return (
    <div tw="flex flex-col w-full">
      {/* <header tw="flex flex-row justify-between items-center w-full">
        <Typography variant="h4">
          <Skeleton width={150} />
        </Typography>
      </header> */}
      <main tw="mt-4">
        <Typography variant="h6" fontWeight="400">
          <Skeleton width="100%" />
          <Skeleton width="100%" />
          <Skeleton width="100%" />
          <Skeleton width="100%" />
          <Skeleton width="100%" />
          <Skeleton width="100%" />
        </Typography>
      </main>
    </div>
  );
};

export const Proposal: React.VFC<ProposalProps> = ({
  proposal: { name, proposal },
}) => {
  return (
    <div tw="flex flex-col w-full">
      <main tw="mt-4">
        <Typography color="primary" variant="h4" fontWeight="400">
          {proposal}
        </Typography>
      </main>
      <header tw="flex flex-row justify-between w-full">
        <Typography color="#555555" width="100%" align="right" variant="h4">
          - {name || 'Anónimo'}
        </Typography>
      </header>
    </div>
  );
};
