module.exports = {
  theme: {
    extend: {
      colors: {
        primary: 'var(--bg-primary)',
        secondary: 'var(--bg-secondary)',
        tertiary: 'var(--color-primary)',
        red: '333333',
      },
    },
    screens: {
      xs: '0px',
      sm: '600px',
      md: '1000px',
      lg: '1200px',
      xl: '1900px',
    },
  },
};
