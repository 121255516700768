import React from 'react';
import { useCollection } from 'react-firebase-hooks/firestore';
import { proposalsCollection } from '../services/firebase';
import tw from 'twin.macro';

export function ProposalsManagerPage() {
  const [snapshot, loading, error] = useCollection(
    proposalsCollection.orderBy('createdAt', 'asc')
  );
  if (loading) return <div></div>;
  const proposals = snapshot.docs.map(
    (doc) => ({ id: doc.id, ...doc.data() } as any)
  );
  async function toggleProposal(proposal) {
    await proposalsCollection
      .doc(proposal.id)
      .update({ active: !proposal.active });
  }
  return (
    <div tw="min-h-screen flex flex-col space-y-4 py-4 w-screen bg-gray-100">
      {proposals.map((proposal) => (
        <div
          tw="bg-white rounded-md shadow-md p-8 w-full mx-auto max-w-lg"
          key={proposal.id}
        >
          <p tw="text-gray-700">{proposal.proposal}</p>
          <p tw="text-gray-500">de {proposal.name}</p>
          <button
            tw="px-4 py-2 text-white rounded-sm shadow-md mt-4"
            style={{
              backgroundColor: proposal.active ? 'red' : 'green',
            }}
            onClick={() => toggleProposal(proposal)}
          >
            {proposal.active ? 'Desactivar' : 'Activar'}
          </button>
        </div>
      ))}
    </div>
  );
}
