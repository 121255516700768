import {
  Breakpoint,
  BreakpointOverrides,
  createMuiTheme,
  responsiveFontSizes,
  Theme,
} from '@material-ui/core/styles';
import {
  Breakpoints,
  BreakpointValues,
} from '@material-ui/core/styles/createBreakpoints';
import React, { createContext, ReactNode, Dispatch } from 'react';
import { theme as t } from 'twin.macro';
import { theme as TailwindTheme } from '../../tailwind.config.js';

/**
 * I take breakpoints defined in tailwind.config.js and put them in
 * here, make sure to declare new breakpoints
 * @see tailwind.config.js
 * @see custom.d.ts
 */

const bpValues: [string, number][] = [];
for (const [key, value] of Object.entries(TailwindTheme.screens)) {
  console.log(value);
  bpValues.push([key, parseInt(value)]);
}

let theme = createMuiTheme({
  palette: {
    primary: {
      main: '#3958a5',
    },
    secondary: {
      main: '#E5CA44',
    },
    background: {
      paper: '#FDFFFC',
    },
  },
  typography: {
    fontFamily: 'Montserrat, sans-serif',
  },
  breakpoints: {
    values: Object.fromEntries(bpValues) as BreakpointValues,
  },
});

theme = responsiveFontSizes(theme);

export default theme;
