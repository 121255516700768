import React from 'react';
import ReactDOM from 'react-dom';
import App from './app/app';
import theme from './theme/theme';
import { ThemeProvider } from '@material-ui/core';
import BaseStyles from './theme/stylesBase';
import { GlobalStyles } from 'twin.macro';
import { Global } from '@emotion/react';
import './styles/normalize.css';
import './styles/global.css';

ReactDOM.render(
  <React.StrictMode>
    <GlobalStyles />
    <Global styles={BaseStyles} />
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
